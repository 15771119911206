import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Media from 'react-media';

import { Container, Sticky } from 'modules/react-sticky/react-sticky';

import { NavTabList } from 'components/commons/_/tabs/NavTabList';

import { ViewSearch } from './tools/Search';

import useStyles from './ListView.jss';

const ListView = props => {
	const { name, description, i18nKey, i18n, tabs, children, search, setSearch, headerBlock } = props;
	const config = {
		search: true,
		...props.config,
	};
	const [titleVisibility, setTitleVisibility] = useState(true);
	const classes = useStyles();

	const $tabs = (
		<div className="px-3 mx-0 mx-sm-3 py-3 pt-sm-4">
			<NavTabList {...tabs} />
		</div>
	);

	return (
		<Container>
			<section
				className={classnames('pb-3 pb-sm-0 mb-6 mb-sm-0 h-100 d-flex flex-column', classes.view, {
					[`view--${name}`]: true,
				})}
			>
				<div className="px-3">
					<section
						className={classnames('d-flex align-items-center px-0 px-sm-3 pt-4 pt-sm-6', classes.title)}
					>
						<div className="d-flex flex-column flex-md-row flex-wrap w-100">
							<div className="d-flex flex-grow-1 justify-content-between align-items-center mr-0 mr-md-2">
								{titleVisibility && (
									<div>
										<h1 className="mb-0 fs-6 fs-sm-8">
											{i18n ? (
												<FormattedMessage id={`${i18n.key}.title`} values={i18n.values} />
											) : (
												<FormattedMessage id={`${i18nKey}.title`} />
											)}
										</h1>
										{description && <h2 className="fs-4 mb-0 d-none d-sm-block">{description}</h2>}
									</div>
								)}
								{config.search && (
									<ViewSearch
										search={search}
										setSearch={setSearch}
										onVisibilityChange={status => setTitleVisibility(!status)}
									/>
								)}
							</div>
							{headerBlock && (
								<div className="d-flex flex-grow-1 align-items-center mt-2 mt-sm-0">{headerBlock}</div>
							)}
						</div>
					</section>
				</div>

				{tabs ? (
					<Fragment>
						<Media query={{ maxWidth: 576 }}>
							<Sticky>{({ isSticky }) => <div className={isSticky && classes.tabs}>{$tabs}</div>}</Sticky>
						</Media>
						<Media query={{ minWidth: 576 }}>{$tabs}</Media>
					</Fragment>
				) : (
					<div className="px-3 mt-2 mt-sm-0 pt-3 pt-sm-4" />
				)}

				<div className="px-3 d-flex flex-column flex-grow-1">{children}</div>
			</section>
		</Container>
	);
};

export default ListView;
